.off-canvas--trigger{
  width: 44px;
  height: 36px;
  display:inline-block;
  overflow:visible;
  margin: 20px 10px 0 0;
  padding: 5px 15px 5px 5px;
  cursor:pointer;
  transition-timing-function:linear;
  transition-duration:.15s;
  transition-property:opacity,-webkit-filter;
  transition-property:opacity,filter;
  transition-property:opacity,filter,-webkit-filter;
  border:0;
  background-color:transparent;

  @media @tabP{
    margin: 29px 0 0 0;
    padding: 5px 0 5px 5px;
  }
  @media @desk{
    margin-top: 39px;
  }
}
.hamburger--box {
  position:relative;
  display:inline-block;
  width:20px;
  height:15px;

  @media @tabP{
    width: 28px;
    height: 21px;
  }
}
.hamburger--inner {
  top:50%;
  display:block;
  margin-top:0;
}
.hamburger--inner,
.hamburger--inner:after,
.hamburger--inner:before {
  position:absolute;
  width:20px;
  height:2px;
  transition-timing-function:ease;
  transition-duration:.15s;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform,-webkit-transform;
  border-radius:3px;
  background-color:@anthrazit;

  @media @tabP{
    width: 28px;
    height: 3px;
  }
}
.off-canvas--trigger:hover,
.off-canvas--trigger:active{
  .hamburger--inner,
  .hamburger--inner:after,
  .hamburger--inner:before {
    background-color:@blue;
  }
}

.hamburger--inner:after,.hamburger--inner:before {
  display:block;
  content:''
}
.hamburger--inner:before {
  top:-5px;
  //left: -8px
  @media @tabP{
    top:-8px;
  }
}
.hamburger--inner:after {
  bottom:-5px;

  @media @tabP{
    bottom:-8px;
  }
}




.off-canvas--trigger {

  .hamburger--inner {
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
    transition-duration: .1s;

    &::before {
      transition: top .1s .14s ease, opacity .1s ease
    }

    &::after {
      transition: bottom .1s .14s ease, -webkit-transform .1s cubic-bezier(.55, .055, .675, .19);
      transition: bottom .1s .14s ease, transform .1s cubic-bezier(.55, .055, .675, .19);
      transition: bottom .1s .14s ease, transform .1s cubic-bezier(.55, .055, .675, .19), -webkit-transform .1s cubic-bezier(.55, .055, .675, .19)
    }
  }
}
// active state
.is--clicked .off-canvas--trigger{

  .hamburger--inner {
    transition-delay:.14s;
    transition-timing-function:cubic-bezier(.215,.61,.355,1);
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);

    &::before{
      top:0;
      transition:top .1s ease,opacity .1s .14s ease;
      opacity:0;
    }

    &::after{
      bottom:0;
      transition:bottom .1s ease,-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
      transition:bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1);
      transition:bottom .1s ease,transform .1s .14s cubic-bezier(.215,.61,.355,1),-webkit-transform .1s .14s cubic-bezier(.215,.61,.355,1);
      -webkit-transform:rotate(-90deg);
      transform:rotate(-90deg)
    }

  }

}