.navigation{
  background: @blue;
  box-shadow: 0 2px 3px rgba(0,0,0,0.3);
  padding: 0;
  margin: 0;
  height: auto;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  .interactionTransition(all, 0.5s);

  &.off-canvas--in{
    padding: @p2 0;
    opacity: 1;
    max-height: 1000px;
    .interactionTransition(all, 0.3s);
  }
}

#identity + .navigation{
  margin-top: 0;
  margin-bottom: 0;
}

#main-menu{
  .interactionTransition();

  a{
    color: @white;
    .interactionTransition();

    &:hover,
    &:focus{
      color: @yellow;
    }

    &:hover .badge,
    .badge{
      padding: 5px 8px;
      line-height: 1;
      border-radius: calc(@stdRadius / 2);
      background: @yellow;
      color: @anthrazit;
      font-size: 1.4rem;
      margin-left: @p1;
      display: inline-block;

      @media @tabP{
        border-radius: @stdRadius;
        padding: @p1;
        margin-left: @p2;
        font-size: 1.8rem;
      }
    }
  }

  & > li{
    font-size: 1.85rem;
    font-weight: 600;

    @media @tabP{
      font-size: 2.4rem;
    }

    a{
      display: flex;
      align-items: center;
      width: 100%;
      min-height: @navLvl1HeightMobile;
      padding: @p1 0;
      box-sizing: border-box;

      @media @tabP{
        min-height: auto;
        padding: 0;
        height: @navLvl1Height;
      }
    }

    &.is--active > a{
      color: @yellow;
    }

    &.has--child{
      display: flex;
      flex-wrap: wrap;
      width: 100%;


      & > a{
        width: 90%;
      }

      & > .sub--trigger{
        width: 10%;
        background: transparent url("../assets/sub-closed-icon.png") no-repeat center center;
        background-size: 21px auto;
        border: 0;

        &.is--active{
          background: transparent url("../assets/sub-open-icon.png") no-repeat center center;
          background-size: 24px auto;

          @media @tabP{
            background: transparent url("../assets/sub-open-icon.png") no-repeat right center;
            background-size: 24px auto;
          }
        }

        @media @tabP{
          background: transparent url("../assets/sub-closed-icon.png") no-repeat right center;
          background-size: 24px auto;
        }
      }
    }
  }


  .sub--menu{
    height: 0;
    padding-left: 2.5rem;
    opacity: 0;
    overflow: hidden;
    .interactionTransition();

    @media @tabP{
      padding-left: 3.2rem;
    }

    li{
      font-weight: 400;

      &.is--active > a{
        color: @yellow;
      }
    }
  }

  .child--is-in > .sub--menu{
    opacity: 1;
    height: 100%;
  }

  .child--is-in > a{
    color: @yellow;
  }
}

.level--3-in #main-menu{
  height: 0;
  opacity: 0;
}

.menu--level-3{
  height: 0;
  opacity: 0;
  overflow: hidden;
  .interactionTransition();

  &.thirdlevel--in{
    height: auto;
    opacity: 1;
  }
}