strong{
  font-weight: 600;
}


.is--yellow{
  color: @yellow;
}
.is--blue{
  color: @blue;
}
.is--medgrey{
  color: @medGrey;
}
.is--white{
  color: @white;
}
.is--half-white{
  color: @halfWhite;
}


p{
  margin: 0 0 @p15 0;
}

p a, ol a{
  color: @blue;
  text-decoration: underline;
  .interactionTransition();

  &:hover{
    text-decoration: none;
  }
}




h1, .h1{
  margin: 0 0 @p1 0;
  font-weight: 600;
  font-family: @stdFont;
  font-size: 2.25rem;
  line-height: 1.1;

  @media @tabP{
    font-size: 3.2rem;
    margin: 0 0 @p2 0;
  }

  @media @desk{
    font-size: 4.5rem;
  }
}
h2, .h2{
  font-weight: 600;
  font-family: @stdFont;
  margin: 0 0 @p2 0;
  font-size: 2.25rem;
  line-height: 1.1;

  @media @tabP{
    font-size: 3.2rem;
  }

  @media @desk{
    font-size: 4.5rem;
  }
}
.has--h2-size{
  font-size: 2.25rem;
  line-height: 1.1;

  @media @tabP{
    font-size: 3.2rem;
  }

  @media @desk{
    font-size: 4.5rem;
  }
}
h3, .h3{
  font-weight: 600;
  font-family: @stdFont;
  margin: 0 0 @p1 0;
  font-size: 2.25rem;
  line-height: 1.1;

  @media @tabP{
    font-size: 2.9rem;
    margin: 0 0 @p2 0;
  }
}
p + h3{
  margin-top: @p2;
}
.has--h3-size{
  font-size: 2.25rem;
  line-height: 1.1;

  @media @tabP{
    font-size: 2.9rem;
  }
}
.card--is-large h3{
  max-width: 740px;
  margin-bottom: 5px;

  @media @tabP{
    margin-bottom: 10px;
  }
}

h4, .h4{
  font-weight: 600;
  font-family: @stdFont;
  margin: 0 0 @p15 0;
  font-size: 1.75rem;
  line-height: 1.1;

  @media @tabP{
    font-size: 2.5rem;
  }
}
h5, .h5{}
h6, .h6{}



h1, h2, h3,
.h1, .h2, .h3{
  a{
    display: block;
    color: inherit;
    background: url("../assets/headline-link-arrow.png") no-repeat right center;
    background-size: 24px auto;

    @media @tabP{
      background-size: 45px auto;
    }
  }

  &:hover a{
    background-image: url("../assets/headline-link-arrow-yellow.png")
  }
}

.bg--blue{
  h1, h2, h3,
  .h1, .h2, .h3{
    a{
      background: url("../assets/headline-link-arrow-white.png") no-repeat right center;
      background-size: 24px auto;

      @media @tabP{
        background-size: 45px auto;
      }
    }

    &:hover a{
      background-image: url("../assets/headline-link-arrow-yellow.png")
    }
  }
}