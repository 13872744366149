img{

  &.img--is-rounded{
    border-radius: 50%;
    overflow: hidden;
    display: block;
    margin-bottom: 0;
  }
}


figure img{
  width: 100%;
  height: auto;
  display: block;
}

figure figcaption{
  padding: @p1;

  &.type--is-small{
    margin: 0;
  }
}
a{
  figcaption{
    color: @anthrazit;
    .interactionTransition();
  }

  &:hover{
    figcaption{
      color: @blue;
    }
  }
}

.container--wide{

  figure figcaption{
    padding:0;
  }

  .figcaption--inner{
    width: 100%;
    padding: @p15;
    box-sizing: border-box;
  }


  @media @tabP{

    .figcaption--inner{
      max-width: 618px;
      margin: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media @desk{

    .figcaption--inner{
      max-width: 866px;
      margin: auto;
    }
  }


  figcaption.has--dark-bg{
    color: @halfWhite;

    .type--is-small{
      letter-spacing: 0.2px;
      line-height: 1.5;
    }
  }

  @media screen and (max-width: 767px){
    .is--mobile-full{
      border-radius: 0;
    }
  }

}



/**
    Floating images in text
 */

.has--intext-img{

  figure{
    margin: @p1 0;

    figcaption{
      &.type--is-small{
        letter-spacing: 0;
        line-height: 1.5;
      }
    }
  }

  @media @tabP{

    display: table;
    max-width: 100%;
    margin: @p15 0;


    &.intext--img-left{

      figure{
        float: left;
        max-width: 50%;
        margin: @p1 @p2 @p1 0;
      }
    }

    &.intext--img-right{

      figure{
        float: right;
        max-width: 50%;
        margin: @p1 0 @p1 @p2;
      }
    }
  }


  @media @desk{
    &.intext--img-left,
    &.intext--img-right{
      figure{
        max-width: 40%;
      }
    }
  }
}