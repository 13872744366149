.level-3-reverse{
  display: none;
  opacity: 0;
  margin-top: calc( -1 * @p2 );
  height: @navLvl1Height;
  padding: @p1 0;
  background: @anthrazit;
  .interactionTransition();

  a{
    display: flex;
    width: 100%;
    align-items: center;
    height: @navLvl1Height;
    color: @white;
    font-weight: 600;
    padding-left: 2.5rem;
    background: url("../assets/menu-back.png") no-repeat left center;
    background-size: 16px auto;
  }
}

.level--3-in{
  .level-3-reverse{
    display: block;
    opacity: 1;
  }
}


.menu--level-3{
  position: relative;
  z-index: 999;

  li{
    margin: @p2 0;
  }
  a{
    color: @white;
    min-height: @navLvl1Height;
    font-size: 2.05rem;
    font-weight: 600;
    display: flex;
    width: 100%;
    flex-direction: column;
    .interactionTransition();

    @media @tabP{
      font-size: 2.4rem;
    }


    .prelink{
      font-size: 1.55rem;
      font-weight: 400;
      display: block;

      @media @tabP{
        font-size: 1.9rem;
      }
    }

    .is--light{
      font-size: 1.55rem;
      font-weight: 400;

      @media @tabP{
        font-size: 1.9rem;
      }
      @media @desk{
        font-size: 2.4rem;
      }
    }


    &:hover,
    &:active{
      color: @yellow;
    }
  }

  .is--active > a{
    color: @yellow;
  }
}