html{
  font-size: 10px;
}

body{
  margin: 0;
  padding: 0;
  font-size: 1.55rem;
  letter-spacing: -0.4px;
  line-height: 2.2rem;
  color: @stdFontColor;
  font-family: @stdFont;
  background: @lightGrey;

  @media @tabP{
    font-size: 1.9rem;
    line-height: 3rem;
  }
}

a{
  text-decoration: none;
}

ul, ol, li{
  padding: 0;
  margin: 0;
}
ul li{
  list-style-type: none;
}

figure{
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

input, textarea{
  outline: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0.0);
  font-family: @stdFont;
  font-size: inherit;
  box-sizing: border-box;
}

button, button:focus, button:active{
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-shadow: none;
  border: 0;
  font-family: @stdFont;
}