.button{
  display: inline-block;
  text-decoration: none;
  padding: @p1 4.5rem @p1 18px;
  border-radius: @slimRadius;
  font-weight: 600;
  background-color: @yellow;
  color: @anthrazit;
  background-image: url("../assets/button-arrow.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 1.4rem) center;
  background-size: 18px auto;
  .interactionTransition();

  &:hover,
  &:active{
    background-color: @anthrazit;
    background-image: url("../assets/button-arrow-white.png");
    color: @white;
  }
}

button.button{
  border: 0;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-size: inherit;
  cursor: pointer;
}