#headerstart > .row.top--no-space:first-child{
  @media @desk{
    padding-top: 25px;
  }
}

.header--hero{
  margin-bottom: 0;

  .container{
    padding-left: 0;
    padding-right: 0;
  }

  .figcaption--inner{
    line-height: 1.25;

    .type--is-small{
      margin-bottom: 0;
    }
  }


  figcaption{
    padding: @p2;
    overflow: hidden;

    .figcaption--inner{
      max-width: 866px;
      margin: auto;
    }


  }


  & + .row:not(.has--dbl-margin){
    margin-top: 0;
  }



  @media @tabL{

    figure{
      position: relative;
      border-top-left-radius: @stdRadius;
      border-top-right-radius: @stdRadius;

      img{
        border-top-left-radius: @stdRadius;
        border-top-right-radius: @stdRadius;
        overflow: hidden;
      }
    }

    figcaption {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      border-bottom-left-radius: @stdRadius;
      border-bottom-right-radius: @stdRadius;

      .figcaption--inner{
        max-width: 618px;
        margin: auto;
      }
    }

  }


  @media @desk{

    figcaption .figcaption--inner{
      max-width: 866px;
      margin: auto;
    }
  }



  .row:last-child{
    .container{

      @media @tabL{
        border-bottom-left-radius: @stdRadius;
        border-bottom-right-radius: @stdRadius;
        overflow: hidden;
      }
    }
  }

}


.is--simple-header{
  position: relative;
  background: @white;

  &::after{
    width: 100%;
    height: 50px;
    background: @lightGrey;
    bottom: 0;
    content: "";
    display: block;
    position: relative;
    margin-top: -50px;
  }

  figure{
    position: relative;
    z-index: 222;


    &.has--border-radius{
      border-radius: 0;


      @media @tabL{
        border-radius: @stdRadius;
      }
    }
  }
}