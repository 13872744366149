.panel{
  margin: 0 0 5px 0;
  border-radius: @stdRadius;
  padding: @p1;
  background: @white;
  overflow: hidden;
  line-height: 1.2;

  @media @tabP{
    margin: 0 0 @p1 0;
  }

  &.bg--blue,
  &.bg--blue a{
    background: @blue;
    color: @white;

    &:hover{
      color: @yellow;
    }
  }

  &.bg--blue a{
    background: url("../assets/round-arrow-white.png") no-repeat 100% 92%;
    background-size: 24px auto;

    &:hover{
      background-image: url("../assets/round-arrow-yellow.png");
    }

    @media @tabP{
      background-size: 32px auto;
    }
  }


  p{
    margin-bottom: calc(@p1 / 2);
  }

  .col:first-child img{
    width: 60px;

    @media @tabP{
      width: 85px;
    }
  }


  &.has--edgeless-img{
    position: relative;
    padding-bottom: 0;

    .col--wrapper{
      -webkit-flex-wrap: unset;
      flex-wrap: unset;
      align-items: start;

      .col:last-child{
        padding-bottom: @p1;

        @media screen and (max-width: 359px){
          padding-left: @p1;
          width: calc(100% - 90px);
        }
      }
    }

    .col:first-child{
      @media screen and (max-width: 359px){
        width: 90px;
      }
    }

    .col:first-child img{
      width: 120px;
      height: auto;
      margin-top: -@p1;
      margin-left: -@p1;
      display: block;
      margin-bottom: 0;

      @media screen and (max-width: 359px){
        width: 100px;
      }

      @media @tabP{
        width: 180px;
      }
    }
  }


  .cols--1-to-2{
    line-height: 1.5;

    .col:first-child img{
      width: 100%;
      height: auto;
    }

    .col p{
      margin-bottom: @p15;

      @media @desk{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }

    a{
      color: @blue;
      text-decoration: underline;
      .interactionTransition();

      &:hover{
        color: @anthrazit;
      }
    }
  }


  &.has--dark-bg{
    border-radius: @slimRadius;
  }

}