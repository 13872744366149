.box--is-yellow{
  background: @lightYellow;
  border-radius: @stdRadius;
  padding: 12px;
  border: 2px solid @yellow;
  margin: @p2 0;

  @media @tabP{
    padding: @p2;
  }


  // special margin def
  & + .divider--slim{
    margin-top: calc(@p2 * 2);
  }

  p{
    margin-bottom: 0;
  }
}