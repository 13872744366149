.infotage--icons{
  padding: 0 0 @p2 0;
  flex-wrap: unset;
  align-items: center;

  @media @tabP{
    padding: @p1 0 @p2 0;
  }

  .col{
    flex: 1;
  }

  .col img{
    max-width: 50px;
    height: auto;
    display: block;
    margin: 0 auto 5px auto;

    @media @tabP{
      max-width: 60px;
    }
  }

  .col.has--arrow-icon img{
    max-width: 12px;
    height: auto;
    display: block;
    margin: auto;
  }

}