footer.website--footer{
  background: @anthrazit;
  letter-spacing: 0.2px;
  color: @medGrey;
  padding: @p2 0 @p15 0;
  margin-bottom: 0;

  @media @tabP{
    padding: @p3 0;
  }

  .type--is-small{
    letter-spacing: 0.2px;
  }

  .container > .col--wrapper{
    flex-direction: column-reverse;

    .footer--col-left{
      text-align: center;
      display: flex;
      flex-direction: column;

      p{
        margin-top: @p2;
      }

      @media @tabP{
        flex-direction: unset;
        padding-top: @p2;

        p, ul{
          flex: auto;
          margin: 0;
          padding: 0;
          line-height: 1;
        }
      }

      @media @desk{
        padding-top: 0;
      }

      .type--is-small{
        font-size: 1.1rem;

        @media @tabP{
          font-size: 1.4rem;
        }

        strong{
          font-size: 1.55rem;

          @media @tabP{
            font-size: 1.4rem;
          }
        }
      }
    }


    .footer--col-right{
      @media screen and (max-width: 359px){
        width: auto;
        margin: auto;
      }


      .col--wrapper{
        justify-content: center;

        @media @tabP{
          justify-content: unset;
        }
      }



      .col{
        flex: unset;

        &:first-child{
          padding-right: 1rem;

          @media @tabP{
            padding-right: 0;
          }
        }

        &:last-child{
          padding-left: 1rem;

          @media @tabP{
            padding-left: 0;
          }
        }

        @media @tabP{
          flex: 1;
        }
      }
    }

    @media @tabP{
      .footer--col-right{
        .col--wrapper .col:first-child{
          padding-right: @p2;
          display: flex;
          -webkit-justify-content: flex-end;
          justify-content: flex-end;

          a{
            display: inline-block;
          }
        }

        .col--wrapper .col:last-child{
          padding-left: @p2;
        }
      }
    }

    @media @desk{
      flex-direction: unset;

      .footer--col-left{
        flex: 5;
        flex-direction: column;
      }

      .footer--col-right{
        flex: 6;
      }
    }


  }




  .logo--img{
    max-width: 200px;
    height: auto;
  }


  #footer--nav{
    margin-top: 0;
    width: auto;
    margin-left: auto;
    margin-right: auto;

    @media @desk{
      text-align: left;
    }

    li{
      flex: unset;
      margin-right: @p15;

      @media @tabP{
        flex: 1;
        margin-right: 0;
      }

      @media @desk{
        justify-content: left;
        flex: unset;
        margin-right: @p15;
      }
    }

    a{
      .interactionTransition();

      &:hover{
        color: @yellow;
      }
    }

    @media @tabP{
      //margin-top: 26px;
      margin-left: 1.5rem;
    }
    @media @desk{
      margin-top: @p2;
      margin-left: 0;
    }
  }


  // links on the right
  #footer--info-link,
  #footer--reg-link{
    padding-left: 40px;
    display: block;
    line-height: 1;

    @media @tabP{
      padding-left: 60px;
    }

    @media screen and (max-width: 359px){
      padding-left: 0;
    }
  }

  #footer--info-link{
    @media screen and (min-width: 360px) {
      background: url("../assets/footericon-nl.png") no-repeat left 3px;
      background-size: 33px auto;
    }
    @media screen and (max-width: 359px) {
      padding-left: @p2;
    }

    &:hover{
      color: @yellow;
      @media screen and (min-width: 360px) {
        background-image: url("../assets/footericon-nl-yellow.png");
      }

      .is--white,
      .is--medgrey{
        color: @yellow;
      }
    }

    @media @tabP{
      background-size: 44px auto;
    }
  }

  #footer--reg-link{
    @media screen and (min-width: 360px) {
      background: url("../assets/footericon-day.png") no-repeat left top;
      background-size: 30px auto;
    }

    &:hover{
      color: @yellow;
      @media screen and (min-width: 360px) {
        background-image: url("../assets/footericon-day-yellow.png");
      }

      .is--white,
      .is--medgrey{
        color: @yellow;
      }
    }

    @media @tabP{
      background-size: 38px auto;
    }
  }
}

main + footer.website--footer{
  margin-top: @p4;
}