#identity{
  width: 100%;
  margin: 0;
  height: 70px;
  background: @white;
  box-shadow: 0 2px 6px rgba(0,0,0,0.15);
  position: relative;

  @media @tabP{
    height: 95px;
  }


  @media @desk{
    height: 115px;
  }

  .container{
    display: flex;
    height: 100%;
  }
}

.nav--is-open #identity{
  box-shadow: none;
}



.logo{
  flex: auto;
  height: 100%;

  a{
    display: inline-flex;
    align-items: center;
    width: auto;
    height: 100%;
    box-sizing: border-box;
    padding-top: 10px;


    img{
      max-width: 170px;
      height: auto;
      display: block;
      margin: 0;

      @media @tabP{
        max-width: 200px;
      }
      @media @desk{
        max-width: 280px;
      }
    }

  }
}


.account{
  width: 35px;
  opacity: 0;
  display: flex;
  .interactionTransition();

  a{
    display: flex;
    height: 100%;
    width: 100%;
    background: url("../assets/account-icon.png") no-repeat center center;
    background-size: 16px auto;

    &:hover,
    &:active{
      background-image: url("../assets/account-icon-blue.png");
    }

    @media @tabP{
      background-size: 21px auto;
    }
  }
}
.nav--is-open .account{
  opacity: 1;
}

#hamburger{
  width: 35px;
  text-align: right;
}