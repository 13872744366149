.row#box--author{
  padding-top: @p1;
  padding-bottom: @p1;
  margin-top: 0;
  margin-bottom: 0;

  .img--is-rounded{
    width: 64px;
    height: auto;

    @media @tabP{
      width: 85px;
      height: auto;
    }
  }
}