.form--row{
  margin-bottom: @p1;

  label{
    display: block;
    line-height: 1.2;
  }

  // hide the honeypot:
  &.validate--for-url{
    height: 0;
    overflow: hidden;
    margin: 0;
  }
}

input[type="text"],
input[type="email"]{
  border: 1px solid @stdBorderColor;
  border-radius: @slimRadius;
  box-shadow: inset 2px 2px 3px rgba(0,0,0,0.1);
  padding: @p1;
  font-size: inherit;
  max-width: 460px;
  display: block;
  width: 100%;

  &.input--full{
    max-width: 100%;
  }
}

textarea{
  border: 1px solid @stdBorderColor;
  border-radius: @slimRadius;
  box-shadow: inset 2px 2px 3px rgba(0,0,0,0.1);
  padding: @p1;
  max-width: 810px;
  display: block;
  width: 100%;
  min-height: 160px;
}

br + input{
  margin-top: @p1;
}
br + textarea{
  margin: @p1 0;
}

label + input[type="text"],
label + input[type="email"],
label + textarea{
  margin-top: 3px;
}

.checkbox--wrapper{
  position: relative;

  input.is--hidden{
    display: inline-block;
    position: absolute;
    top: 6px;
  }
}

.checkbox--wrapper label{
  display: flex;
  width: 100%;
  position: relative;
  padding-left: 35px;

  .label--inner{
    margin-top: 2px;
    width: calc(100% - 36px);

    a{
      color: @blue;
      text-decoration: underline;
    }
  }
}
.is--nice-checkbox{
  display: inline-block;
  width: 26px;
  height: 26px;
  box-sizing: border-box;
  border: 2px solid @stdBorderColor;
  border-radius: 3px;
  margin-right: @p1;
  position: absolute;
  top: -1px;
  left: 0;
  background: #fff;
}

input[type="radio"]:checked + label .is--nice-checkbox,
input[type="checkbox"]:checked + label .is--nice-checkbox{
  flex-shrink: 0;
  &::before{
    position: absolute;
    width: 20px;
    height: 2px;
    background: @anthrazit;
    display: block;
    bottom: 41%;
    left: 1px;
    transform: rotate(-45deg);
    content: "";
  }

  &::after{
    position: absolute;
    width: 20px;
    height: 2px;
    background: @anthrazit;
    display: block;
    bottom: 43%;
    left: 1px;
    transform: rotate(45deg);
    content: "";
  }
}

.privacy--error{
  padding: 5px 10px 5px 34px;
  color: #b01731;
}