blockquote{
  border-left: 3px solid @anthrazit;
  padding: @p1 @p2;
  margin: 0 0 @p2 0;

  font-weight: 600;
  font-family: @stdFont;
  font-size: 1.85rem;
  line-height: 2.4rem;

  @media @tabP{
    font-size: 2.9rem;
    line-height: 1.4;
  }
}