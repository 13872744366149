hr{
  margin: @p2 0;
  display: block;
  border: 0;

  &.divider--slim{
    height: 1px;
    background: @stdBorderColor;
  }
}

.panel + hr{
  margin-top: 25px;
  margin-bottom: 18px;
}