/* Silka 400 - latin */
@font-face {
  font-family: 'Silka';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/silka-regular-webfont.eot');                                 /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/silka-regular-webfont.eot?#iefix') format('embedded-opentype'),   /* IE6-IE8 */
  url('../fonts/silka-regular-webfont.woff2') format('woff2'),                    /* Super Modern Browsers */
  url('../fonts/silka-regular-webfont.woff') format('woff'),                      /* Modern Browsers */
  url('../fonts/silka-regular-webfont.ttf') format('truetype');                   /* Safari, Android, iOS */
}

/* Silka 600 - latin */
@font-face {
  font-family: 'Silka';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/silka-bold-webfont.eot');                                /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/silka-bold-webfont.eot?#iefix') format('embedded-opentype'),  /* IE6-IE8 */
  url('../fonts/silka-bold-webfont.woff2') format('woff2'),                   /* Super Modern Browsers */
  url('../fonts/silka-bold-webfont.woff') format('woff'),                     /* Modern Browsers */
  url('../fonts/silka-bold-webfont.ttf') format('truetype');                  /* Safari, Android, iOS */
}
