.regform{
  position: fixed;
  z-index: 880;
  width: calc(100% - 30px);
  height: calc(100vh - 30px);
  max-height: 363px;
  top: 50%;
  transform: translate3d(0, -231px,0) scale(0.1);
  opacity: 0;
  visibility: hidden;
  bottom: 15px;
  left: 15px;
  right: 15px;
  box-shadow: 0 0 @p2 rgba(0,0,0,0.5);
  background: @white;
  border-radius: @stdRadius;
  .interactionTransition();

  &.form--in{
    transform: translate3d(0, -231px,0) scale(1);
    opacity: 1;
    visibility: visible;
  }

  @media @tabP{
    width: 618px;
    max-height: 377px;
    left: 50%;
    margin-left: -309px;
  }

  @media @desk{
    width: 866px;
    margin-left: -433px;
  }




  .regform--closer{
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    background: url("../assets/close-x.png") no-repeat center center;
    background-size: 24px auto;
    border: 0;
  }
}

.regform--inner{
  padding: @p2 @p15 @p2 @p15;
}