.row{
  margin: @p1 0;
  width: 100%;

  // blue row
  &.bg--blue{
    background: @blue;
    color: @white;
    padding: @p2 0 @p1 0;
  }

  // grey row
  &.bg--lightgrey{
    background: @lightGrey;
    padding: @p2 0 @p1 0;
  }



  // the no space defs
  &.btm--no-space{
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media @onlyPhone{
    &.btm--no-space_m{
      margin-bottom: 0;
    }
    &.top--no-space_m,
    & + div.row.top--no-space_m{
      margin-top: 0;
    }
  }
  &.top--no-space{
    margin-top: 0;
    padding-top: 0;
  }

  &.has--btm-p2{
    padding-bottom: @p1;
    @media @tabP{
      padding-bottom: @p2;
    }
  }

  &.has--top-p2{
    padding-top: @p1;
    @media @tabP{
      padding-top: @p1;
    }
  }

  & + .row:not(.top--no-space){
    margin-top: @p1;

    @media @tabP{
      margin-top: @p2
    }
  }
}


// make them available not only for rows
.has--btm-p2{
  padding-bottom: @p2;
}

.has--top-p2{
  padding-top: @p2;
}

.btm--no-space{
  margin-bottom: 0;
}

/*

    THE LAYOUT BOX DEFS

 */
.row{

  .container{
    width: 100%;
    box-sizing: border-box;
    padding: 0 1.5rem;
    margin: 0 auto;

    @media @tabP{
      padding: 0;
      max-width: 618px;
    }

    @media @desk{
      max-width: 866px;
    }





    &.container--wide{

      padding: 0;

      @media @tabP{
        max-width: 100%;
      }

      @media @tabL{
        max-width: 866px;
      }

      @media @desk{
        max-width: 1015px;
      }
    }


  }
}




/*

    COLUMN HANDLING

 */
.col--wrapper{
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media @tabP{
    .col{
      flex: 1;
    }
  }

  // e.g. home, the cols below the  promo video
  &.cols--equal{
    .col:not(.col--full){
      flex: 1;
    }
  }


  .col--full{
    flex: auto;
    width: 100%;
  }




  &.cols--1-to-9{

    .col:first-child{
      flex: 1
    }
    .col:last-child{
      flex: 9;
      box-sizing: border-box;
      padding-left: @p2;
    }
  }


  @media @onlyPhone{
    &.cols--3-to-7_m{
      .col:first-child{
        flex: 3
      }
      .col:last-child{
        flex: 7;
        box-sizing: border-box;
        padding-left: @p15;
      }
    }

    &.cols--3-to-9_m{
      .col:first-child{
        flex: 3
      }
      .col:last-child{
        flex: 9;
        box-sizing: border-box;
        padding-left: @p15;
      }
    }
  }


  &.cols--2-to-8{

    .col:first-child{
      flex: 2
    }
    .col:last-child{
      flex: 8;
      box-sizing: border-box;
      padding-left: @p2;
    }
  }




  &.cols--1-to-2{

    .col:first-child{
      flex: 1;
    }

    .col:last-child{
      flex: 2;
      box-sizing: border-box;
      padding-left: @p1;

      @media @tabP{
        padding-left: @p1;
      }
    }
  }



  &.cols--has-gap{

    @media @tabP{
      .col:first-child{
        margin-left: 0;
      }
      .col{
        margin-left: @p1;
        margin-right: @p1;
      }
      .col:last-child{
        margin-right: 0;
      }
    }
  }

  &.cols--vertical-centered{
    align-items: center;
  }
}