

// COLORS
@anthrazit:         #282b30;
@white:             #ffffff;
@halfWhite:         rgba(255,255,255,0.5);
@yellow:            #ffd800;
@lightYellow:       #f1ebc5;
@lightGrey:         #eef0f6;
@medGrey:           #6f7580;
@blue:              #0e70b8;

@stdBorderColor:   #d9d9d9;


// GENERIC COLOR DEFINITIONS
@stdFontColor:      @anthrazit;



// FONTS
@stdFont:           "Silka", Helvetica, Calibri, Roboto, sans-serif;




// STD SPACES
@p1:                10px;
@p15:               15px;
@p2:                20px;
@p3:                30px;
@p4:                40px;



// STD BORDER RADIUS
@stdRadius:         9px;
@slimRadius:        5px;


// STD SLEEK SHADOW
@stdShadow:         0 0 @p2 rgba(0,0,0,0.1);


// NAV ITEMS
@navLvl1Height:       50px;
@navLvl1HeightMobile: 45px;


// BREAKPOINTS
@onlyPhone:  ~"screen and (max-width: 767px)";
@tabP:       ~"screen and (min-width: 768px)";
@tabL:       ~"screen and (min-width: 1024px)";
@desk:       ~"screen and (min-width: 1281px)";