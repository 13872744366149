.list{
  background: @white;
  border-radius: @stdRadius;
  margin-bottom: @p15;
  line-height: 1.1;

  li{
    padding: @p1;
  }

  li:not(:last-child){
    border-bottom: 1px solid @stdBorderColor;
  }


  &.list--has-icon{
    li{
      padding-left: 3.6rem;
      background-position: 1.2rem center;
      background-repeat: no-repeat;
    }

    &.list--has-icon_plus li{
      background-image: url("../assets/list-plus.png");
      background-size: 13px auto;
    }

    &.list--has-icon_checkmark li{
      background-image: url("../assets/list-checkmark.png");
      background-size: 16px auto;
    }

    &.list--has-icon_download li{
      background-image: url("../assets/list-download.svg");
      background-size: 16px auto;

      a{
        color: @anthrazit;
        .interactionTransition();

        &:hover{
          color: @blue;
        }
      }
    }
  }



  &.is--vertical{
    display: flex;
    width: 100%;

    li{
      flex: auto;
      margin-right: @p1;

      &:last-child{
        margin-right: 0;
      }
    }
  }


  &.is--pure{
    background: transparent;
    border-radius: 0;

    li{
      margin: 0;
      padding: 0;
      background: transparent;
      border: 0;
    }
  }


  &.list--is-inline{
    display: table;
    max-width: 100%;

    li{
      padding-right: 1.5rem;
    }
  }



  &.list--has-links{

    a{
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding-right: 3rem;
      color: @anthrazit;
      background: url("../assets/headline-link-arrow.png") no-repeat right center;
      background-size: 22px auto;
    }
  }
}




.list--standard{
  margin-bottom: @p15;

  li{
    padding-left: @p15;
    position: relative;

    &::after{
      position: absolute;
      width: 5px;
      height: 5px;
      top: 10px;
      left: 0;
      border-radius: 3px;
      background: @anthrazit;
      content: "";


      @media @tabP{
        top: 13px;
      }
    }
  }
}



.list--sitemap{
  margin-bottom: 0;

  & > li{
    font-weight: 600;

    ul {
      margin-left: @p3;
      font-weight: 400;
      margin-bottom: @p3;

      li{
        padding-left: @p15;
        position: relative;

        &::after{
          position: absolute;
          width: 5px;
          height: 5px;
          top: 8px;
          left: 0;
          border-radius: 3px;
          background: @anthrazit;
          content: "";

          @media @tabP{
            top: 12px;
          }
        }
      }
    }

    a{
      color: @blue;
      .interactionTransition();

      &:hover,
      &:focus{
        text-decoration: underline;
      }
    }
  }
}


// ordered lists
ol{
  margin: 0 0 @p15 0;
  padding: 0;

  li{
    margin-left: @p2;
    padding-left: 5px;
  }
}