.video{
  margin: @p1 0;
  border-radius: @stdRadius;
  overflow: hidden;

  .video--inner{
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.card .video{
  margin: 0;
  border-top-left-radius: @stdRadius;
  border-top-right-radius: @stdRadius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.video--caption{
  padding: @p1 @p2 0 @p2;
}


.video--promo-bar{

  .col{
    padding: 13px 5px;

    @media @tabP{
      padding: 13px @p1;
    }
  }

  .col:first-child{
    .hasBorder( right );
  }

  .col:nth-child(3){
    .hasBorder( left );
  }

  .col:last-child{
    .hasBorder( top );
  }

  .col img{
    width: 30px;
    height: auto;
    display: block;
    margin: 0 auto 5px auto;

    @media @tabP{
      width: 48px;
    }
  }
}