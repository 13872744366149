.has--std-margin{
  margin: @p1 0;
}
.has--btm-std-margin{
  margin-bottom: @p1;
}
.has--top-std-margin{
  margin-top: @p1;
}

.bg--blue .has--btm-std-margin{
  margin-bottom: 5px;

  @media @tabP{
    margin-bottom: @p1;
  }
}

.has--dbl-margin{
  margin: @p2 0;
}
.has--btm-dbl-margin{
  margin-bottom: @p2;
}
.has--top-dbl-margin{
  margin-top: @p2;
}

div.has--p2,
.has--p2{
  padding: @p2;
}



.has--white-bg{
  background: @white;
}
div.has--dark-bg,
.has--dark-bg{
  background: @anthrazit;
  color: @white;
  letter-spacing: 0.2px;
}



// the standard border radius
.has--border-radius{
  border-radius: @stdRadius;
}
figure.has--border-radius{
  overflow: hidden;
}

.is--mobile-full.has--border-radius{
  border-radius: 0;
  @media @tabL{
    border-radius: @stdRadius;
  }
}


// central aligned text
.is--centered{
  text-align: center;
}


// the small type: deskt 15px
.type--is-small{
  font-size: 1.1rem;
  margin-bottom: 5px;
  letter-spacing: 1px;

  @media @tabP{
    font-size: 1.55rem;
  }

  &.btm--no-space{
    margin-bottom: 0;
  }
}
figcaption .type--is-small{
  margin-bottom: 0;
}
.has--dark-bg .type--is-small{
  letter-spacing: 0.2px;
  line-height: 1.5;
}


// the large type, for teaser: desk 25px
.type--is-large{
  font-size: 1.75rem;
  line-height: 2.2rem;

  @media @tabP{
    font-size: 2.5rem;
    line-height: inherit;
  }
}
p.type--is-large{
  margin-bottom: 0;
}

// all letters as caps
.is--capitalized{
  text-transform: uppercase;
}


// gerneral reduced lineheight to 1.2 times the font size
.has--reduced-lineheight{
  line-height: 1.25;
}


// very sleek and large shadow
.is--shadowed{
  box-shadow: @stdShadow;
}

@media @desk {
  .hide--desktop{
    display: none;
  }
}


// for only being showed in desktop size
.show--desktop{
  display: none;

  @media @desk{
    display: flex;
  }
}



// always hidden
.is--hidden{
  display: none;
}