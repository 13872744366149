.card{
  display: block;
  width: 100%;
  height: 100%;
  border-radius: @slimRadius;
  overflow: hidden;
  background: @white;
  color: @anthrazit;
  .interactionTransition();

  &:hover,
  &:active{
    color: @blue;
  }

  figcaption{
    padding: @p1;
  }


  &.card--is-large{
    margin-bottom: @p1;

    @media @tabP{
      margin-bottom: @p2;
    }

    figcaption{
      padding: @p2 @p2 0 @p2;

      hr{
        margin-left: -(@p2);
        margin-right: -(@p2);
      }

      .col--wrapper + hr{
        margin-top: @p2;
        margin-bottom: @p15;
      }

      p{
        line-height: 1.25;
      }

      p:last-child{
        padding-right: 4rem;
        background: url("../assets/headline-link-arrow.png") no-repeat right bottom;
        background-size: 28px auto;
      }
    }
  }
}


.card--grid{

  .col{
    margin-bottom: @p1;
  }

  // the gap between the cols
  @media @tabP{
    .col:first-child{
      padding-right: calc(@p1 / 2);
    }

    .col:last-child{
      padding-left: calc(@p1 / 2);
    }
  }

  figure{
    display: flex;
    flex-direction: column;
    height: 100%;
  }


  a figcaption{
    padding-right: 3rem;
    margin-right: 1rem;
    flex: auto;
  }

  a:not(.is--lightboxed) figcaption{
    background: url("../assets/round-arrow.png") no-repeat center right;
    background-size: 32px auto;
  }

  a.card:not(.is--lightboxed):hover figcaption{
    background-image: url("../assets/round-arrow-blue.png");
  }


  & + h2{
    margin-top: @p2;
  }
}

#cards--home{
  h2 + p{
    line-height: 1.25;
  }
}